<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action>
        <close class="close" @click.prevent="cancelPacketReceive" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("dashboard.receive") }}</h2>
        <h1>{{ $t("packetC2Creceive.packet") }}</h1>
      </template>
    </AppHeader>
    <main
      :class="[
        !consignRequested &&
          (packetReceived ? 'bg-light-green' : 'bg-light-pink')
      ]"
    >
      <section
        v-if="packetReceived"
        class="algae-green"
        :style="{ paddingBottom: footerHeight + 'px' }"
      >
        <check-icon :size="190" />
        <h1>{{ $t("packetC2Creceive.packet-received") }}</h1>
        <Errors :errors="errors" />
      </section>
      <section v-else>
        <Errors :errors="errors" :errorBig="true" />
      </section>
    </main>
    <footer ref="footer" v-if="packetReceived">
      <div class="container">
        <PrintButton
          v-for="(printType, index) in printables"
          :key="index"
          :printType="printType"
        />
        <a href="#" class="btn btn-primary btn-green" @click.prevent="finish">{{
          $t("packetC2Creceive.finish")
        }}</a>
      </div>
    </footer>
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import CheckIcon from "vue-material-design-icons/Check.vue";
import Close from "vue-material-design-icons/Close.vue";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import Spinner from "@/components/Spinner";
import PrintButton from "@/components/PrintButton";

const REPEAT_CHECK_STATUS = 1000;

export default {
  name: "PacketReceive",
  mixins: [setActualFooterHeight, getParams],
  components: { Errors, AppHeader, CheckIcon, Close, Spinner, PrintButton },
  data() {
    return {
      code: this.$route.params.code
    };
  },

  beforeRouteLeave(to, from, next) {
    this.clearErrors();
    this.resetPacketAndPaymentState();
    next();
  },

  beforeDestroy() {
    this.stopRefresh();
  },

  async mounted() {
    if (this.consignRequested) {
      this.periodicallyRefreshConsignment();
    } else if (this.consignNotConsigned) {
      // If consign isn't consigned, we need to set error here
      // because rerouting from PacketSearch to PacketReceive
      // clears all errors
      this.setConsignError();
    }
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("packet", ["packetBarcode", "packetReceived", "printables"]),
    ...mapGetters("packet", ["consignRequested", "consignNotConsigned"])
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", ["getC2CConsignDetail", "setConsignError"]),
    ...mapMutations(["clearErrors", "setLoading"]),

    periodicallyRefreshConsignment() {
      // We need constant loading if consignment state is "requested"
      // rather than managing load inside request actions
      this.setLoading(true);

      this.intervalId = setInterval(async () => {
        const finished = await this.getC2CConsignDetail();

        finished && this.stopRefresh();
      }, REPEAT_CHECK_STATUS);
    },

    cancelPacketReceive() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    },

    finish() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    },

    stopRefresh() {
      clearInterval(this.intervalId);
      this.setLoading(false);
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  text-align: center;
  height: calc(100vh - 56px);

  section {
    padding-top: 100px;
  }
}

footer {
  a:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
